<template>
  <div class="Cooperation">
    <h1>Cooperation Project</h1>
    <Cooperation></Cooperation>
  </div>
</template>

<script>
import Cooperation from '../components/CooperationProject.vue'
export default{
components:{
  Cooperation
}
}
</script>
