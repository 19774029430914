<template>
  <div class="Current">
    
    <Current></Current>
  </div>
</template>

<script>
import Current from '../components/CurrentStudent.vue'
export default{
components:{
  Current
}
}
</script>
