<template>
  <div class="activities" >
    
    <activities></activities>
  </div>
</template>

<script>
import activities from '../components/ActivitiesActivities.vue'
export default{
components:{
  activities
}
}
</script>
