<template>
  <div class="Contact">
    
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from '../components/ContactContact.vue'
export default{
components:{
  Contact
}
}
</script>
