<template>
  <div >
    
   
    <!--<img alt="TSAO" src="../assets/1.jpg" width="40%">-->
    <HomeHome />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHome from '@/components/HomeHome.vue'

export default {
  name: 'HomeView',
  components: {
    HomeHome
  }
}
</script>
