<template>
  <div class="Publication">
    <h1>JOURNAL PAPERS</h1>
    <Publication></Publication>
  </div>
</template>

<script>
import Publication from '../components/PublicationPublication.vue'
export default{
components:{
  Publication
}
}
</script>
