<template>
  <div class="News">
    
    <News></News>
  </div>
</template>

<script>
import News from '../components/NewsNews.vue'
export default{
components:{
  News
}
}
</script>
