<template>
  <div class="Research">
    <h1>學長姐論文</h1>
    <Research></Research>
  </div>
</template>

<script>
import Research from '../components/ResearchProject.vue'
export default{
components:{
  Research
}
}
</script>
