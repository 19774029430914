<template>
  <div class="Graduated">
    
    <Graduated></Graduated>
  </div>
</template>

<script>
import Graduated from '../components/GraduatedStudent.vue'
export default{
components:{
  Graduated
}
}
</script>
