import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PublicationView from '../views/PublicationView.vue'
import ActivitiesView from '../views/ActivitiesView.vue'
import ResearchProjectView from '../views/ResearchProjectView.vue'
import CooperationProjectView from '../views/CooperationProjectView.vue'
import CurrentStudentView from '../views/CurrentStudentView.vue'
import GraduatedStudentView from '../views/GraduatedStudentView.vue'
import NewsNewsView from '../views/NewsNewsView.vue' 
import ContactContactView from '../views/ContactContactView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/News',
    name: 'News',
    component: NewsNewsView
  },
  {
    path: '/Publication',
    name: 'Publication',
    component: PublicationView
  },
  {
    path: '/Research',
    name: 'Research',
    component: ResearchProjectView
  },
  {
    path: '/Cooperation',
    name: 'Cooperation',
    component: CooperationProjectView
  },
  {
    path: '/activities',
    name: 'activities',
    component: ActivitiesView
  },
  {
    path: '/Current',
    name: 'Current',
    component: CurrentStudentView
  },
  {
    path: '/Graduated',
    name: 'Graduated',
    component: GraduatedStudentView
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: ContactContactView
  }

]

const router = new VueRouter({
  routes
})

export default router
