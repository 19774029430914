<template>
  <div>
    
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      fade
      indicators  
      no-hover-pause="on"    
      data-bs-ride="carousel"
      ride="carousel"
      pause="false"
      background="#ababab"
      img-width="1024"
      img-height="480"
      
      style="text-shadow: 1px 1px 2px #333 ;">
      
      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Ting Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100 "
            
            
            width="1024"
            height="480"
            src="../assets/home/image3.jpg"
            alt=""
            style="opacity:0.8;"> 
         
        </template>
      </b-carousel-slide>

      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Tsing Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="../assets/home/image1.jpg"
            alt="image slot"
            style="opacity:0.8;">
        </template>
      </b-carousel-slide>

      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Ting Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="../assets/home/image2.jpg"
            alt="image slot"
            style="opacity:0.8;">
        </template>
      </b-carousel-slide>

      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Ting Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="../assets/home/image6.jpg"
            alt="image slot"
            style="opacity:0.8;">
        </template>
      </b-carousel-slide>

      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Ting Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="../assets/home/image7.jpg"
            alt="image slot"
            style="opacity:0.8;">
        </template>
      </b-carousel-slide>

      <b-carousel-slide caption="清華大學智慧人因創新實驗室 ">
        <p>
          National Ting Hua University - Smart Ergonomic Innovations
        </p>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="../assets/home/image14.jpg"
            alt="image slot"
            style="opacity:0.8;">
        </template>
      </b-carousel-slide>
    </b-carousel>

    <h1></h1>
    
    <b-jumbotron header="Introduction📣" ></b-jumbotron>
    <b-row>
      <b-col cols="1"></b-col>
      <b-col cols="5">
              <b-card>
                  <img style="width:100%;" src="../assets/home/home.png" />                  
              </b-card>
            </b-col>
            <b-col cols="5">
            <b-card   title="SEI Lab">
                
                <b-card-text style="text-align: center; color:black " class="text " >由張堅琦教授所帶領的 SEI Lab ，</b-card-text>
                <b-card-text style="text-align: center; color:black " class="text " >以動作分析、人工智慧、擴增實境、人體計測、</b-card-text>
                <b-card-text style="text-align: center; color:black " class="text " >感性工學、組織安全與人為失誤等六大面向做為研究方向，</b-card-text>
                <b-card-text style="text-align: center; color:black " class="text " >將生物力學、心理學、人機互動等基礎學門，</b-card-text>
                <b-card-text style="text-align: center; color:black " class="text " >結合智慧科技儀器運用於工作現場評估，多年來</b-card-text>
                <b-card-text style="text-align: center; color:black " class="text " >致力於相關人才培育，以推廣人因工程創新研究為宗旨。</b-card-text>
               
            </b-card>
          </b-col>
          <b-col cols="1"></b-col>
    </b-row>
    <div>
            <b-jumbotron header="News📣" ></b-jumbotron>
            <div>
                <b-card-group deck>
                
                <b-card title="歡迎新生加入SEI Lab✨" tag="article" img-top>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities1.jpg" >
                
                    <b-card-text>
                    🕙 2023.07.01
                    </b-card-text>
                    <b-button href="#" variant="primary" v-b-modal.modal-1> More... </b-button>
                      
                </b-card>
    
                <b-card title="恭喜學長姐畢業🎉" tag="article" img-top>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities3.jpg" >
                    <b-card-text>
                    🕙 2023.07.31
                    </b-card-text>
                    <b-button href="#" variant="primary" v-b-modal.modal-2> More... </b-button>
                </b-card>
    
                <b-card title="羽毛球運動🏸" tag="article" img-top>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities5_1.jpg" >
                    <b-card-text>
                    🕙 2023.09.21
                    </b-card-text>
                    <b-button href="#" variant="primary" v-b-modal.modal-3> More... </b-button>
                </b-card>
    
                <b-card title="教師節活動🎁" tag="article" img-top>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities7.jpg" >
                    <b-card-text>
                    🕙 2023.09.28
                    </b-card-text>
                    <b-button href="#" variant="primary" v-b-modal.modal-4> More... </b-button>
                </b-card>
    
                </b-card-group>
    
                
    
                <div>
                  <b-modal id="modal-1" title="歡迎新生加入Max Lab✨">
                    <p class="my-4">🕙 2023.07.01</p>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities1.jpg" >
                  </b-modal>
                </div>
                
                <div>
                  <b-modal id="modal-2" title="恭喜學長姐畢業🎉">
                    <p class="my-4">🕙 2023.07.31</p>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities3.jpg" >
                  </b-modal>
                </div>
                
                <div>
                  <b-modal id="modal-3" title="Max Lab 羽毛球運動🏸">
                    <p class="my-4">🕙 2023.09.21</p>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities5_1.jpg" >
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities5_2.jpg" >
                  </b-modal>
                </div>
                
                <div>
                  <b-modal id="modal-4" title="教師節活動🎁">
                    <p class="my-4">🕙 2023.09.28</p>
                    <img class="card-img-top d-block img-fluid w-100" src="../assets/activities/activities7.jpg" >
                  </b-modal>
                </div>
                
                
            </div>
        </div>
    <div>
      
      
    </div>
      
      
        
     

    
  

    
  </div>

  
  
</template>

<script>
export default {
  name: "HomeHome",
  props: {
    msg: String,
  },
  data(){
    return{
    
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.text {
  font-size: 20px; /* 这里的16px可以根据您的需要进行修改 */
  text-align: left;
}

</style>
